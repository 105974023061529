import Service from '@/js/service.js';

const service = new Service(`${process.env.VUE_APP_RESOURCE_MANAGER_SERVICE}`);

const resourceManagerService = {
    getHttpDownloadResourceTags(then, error) {
        service.getRequest(
            0, 'resourceTags/http_download',
            null,
            then,
            error,
        );
    },
};

export default resourceManagerService;